import { createApp } from 'vue';
import VueApp from './App.vue';
//import Signin from './Signin.vue';
import { config } from 'pixi-live2d-display';
import { App } from '@/app/App';
import { Configuration } from './utils/Configuration';
import { RunMode } from './app/RunMode';
import { RedirectLoginOptions, createAuth0Client } from '@auth0/auth0-spa-js';
import vuetify from './plugins/vuetify';
import './main.css';

/*const routes = {
    '/': VueApp,
    '/login': Login
}

const NotFound = { template: '<p>Page not found<>' };

new Vue({
   el: '#app',
   data: {
    currentRoute: window.location.pathname
   },
   computed: {
    ViewComponent () {
        return routes[this.currentRoute] || NotFound
    }
   }
});*/

const main = async () => {
    switch (window.location.pathname) {
        case '/signin': {
            /*(window as any).vueApp = new (Vue as any)({
                vuetify,
                render: (h: any) => h(Signin),
            }).$mount('#app');*/
            const auth0Config: any = {
                "domain": Configuration.Auth0Domain,
                "clientId": Configuration.Auth0ClientId
            }
            const auth0RedirectConfig: RedirectLoginOptions = {
                authorizationParams: {
                    redirect_uri: Configuration.Auth0RedirectUri
                }
            }
            const auth0Client = await createAuth0Client(auth0Config);
            await auth0Client.loginWithRedirect(auth0RedirectConfig);
            break;
        }
        case '/authenticated': {
            const auth0Config: any = {
                "domain": Configuration.Auth0Domain,
                "clientId": Configuration.Auth0ClientId
            }
            const auth0Client = await createAuth0Client(auth0Config);
            /*const callback = await auth0Client.handleRedirectCallback();
            const accessToken = await auth0Client.getTokenSilently();
            console.log(accessToken);*/
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const state = urlParams.get('state');
            const authSession = JSON.parse(sessionStorage.getItem('a0.spajs.txs.' + Configuration.Auth0ClientId) || '{}');
            const codeVerifier = authSession?.code_verifier;
            fetch(Configuration.ApiUrl + '/token', {
                credentials: 'include',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({ code: code, state: state, code_verifier: codeVerifier })
            }).then(resp => {
                console.log('Authenticated');
                history.replaceState({}, document.title, "/");
                launchApp();
                //fetch(Configuration.ApiUrl + '/ping', { credentials: 'include' }).then(resp => { // TEST CODE
                //    console.log('^^^^^^^^^^^^^');
                //    console.log(resp);
                //});
            });
            break;
        }
        default: {
            fetch(Configuration.ApiUrl + '/ping', { credentials: 'include' }).then(async resp => {
                if(resp.status == 401) {
                    console.log('Authentication Failed, redirecting to login.');
                    if (Configuration.LocalAuth) {
                        window.location.href = Configuration.SignInUrl;
                    } else {
                        const auth0Config = {
                            "domain": Configuration.Auth0Domain,
                            "clientId": Configuration.Auth0ClientId
                        }
                        const auth0RedirectConfig: RedirectLoginOptions = {
                            authorizationParams: {
                                redirect_uri: Configuration.Auth0RedirectUri
                            }
                        }
                        const auth0Client = await createAuth0Client(auth0Config);
                        if (await auth0Client.isAuthenticated()) {
                            launchApp();
                        } else {
                            await auth0Client.loginWithRedirect(auth0RedirectConfig);
                        }
                    }
                } else {
                    console.log('Authenticated');
                    launchApp();
                }
            });
            break;
        }
    }
}

const launchApp = () => {
    //Vue.config.productionTip = false;
        
    //Vue.directive('visible', function(el, binding) {
    //    el.style.visibility = !!binding.value ? 'visible' : 'hidden';
    //});

    createApp(VueApp).use(vuetify).mount("#app");

    config.logLevel = config.LOG_LEVEL_ERROR;

    (window as any).App = App;
    (window as any).config = config;
}

main();

/*// Verify auth
fetch(Configuration.ApiUrl + '/ping', { credentials: 'include' }).then(resp => {
    if(resp.status == 401) {
        //console.log('Authentication Failed, redirecting to login.');
        //window.location.href = Configuration.SignInUrl;
        (window as any).vueApp = new (Vue as any)({
            vuetify,
            render: (h: any) => h(Register),
        }).$mount('#app');
    } else {
        Vue.config.productionTip = false;

        Vue.directive('visible', function(el, binding) {
            el.style.visibility = !!binding.value ? 'visible' : 'hidden';
        });
        
        (window as any).vueApp = new (Vue as any)({
            vuetify,
            render: (h: any) => h(VueApp),
        }).$mount('#app');

        config.logLevel = config.LOG_LEVEL_ERROR;

        (window as any).App = App;
        (window as any).config = config;
    }
});*/
