import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2315c472"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-wrap gap-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, { class: "ptb-12" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_list_group, null, {
        activator: _withCtx(({ props }) => [
          _createVNode(_component_v_list_item, _mergeProps(props, { title: "Vision" }), null, 16)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_v_radio_group, {
                  modelValue: _ctx.selection,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selection) = $event)),
                  "hide-details": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_radio, {
                      label: "Camera",
                      value: "camera"
                    }),
                    _createVNode(_component_v_radio, {
                      label: "Image",
                      value: "image"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}