<template>
    <v-dialog persistent max-width="800" scrim="#000" v-model="isActiveLocal">
      <v-card class="d-flex flex-column">
        <v-toolbar color="primary" flat dense class="flex-grow-0">
          <v-toolbar-title>User Management</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-8">
          <div class="field-row">Tier: <span class="value">{{ tierName }}</span> <a @click="upgrade">[ Manage Subscription ]</a></div>
          <div class="field-row last-row">Upgrade expires: <span class="value">{{ upgradeExpiresString }}</span></div>
          <div class="label">Your Name (50 chars max):</div>
          <v-text-field single-line filled label="Name" maxlength="50" v-model="name" placeholder="Enter your name (how characters will address you)"></v-text-field>
          <div class="label">Your Description (250 chars max):</div>
          <v-textarea filled id="textmessage" maxlength="250" v-model="description" placeholder="Enter a description (gender, age, appearance etc.)"></v-textarea>
          <div class="d-flex align-center">
            <v-btn color="blue-grey" @click="$emit('close')">Cancel
              <v-icon class="v-btn-right-icon">mdi-close-circle-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue-grey" @click="save">Save
              <v-icon class="v-btn-right-icon">mdi-content-save-check-outline</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  
  <script lang="ts">
  import { UserType } from '../data/UserType';
  import { Tier } from '@/app/Tier';
  import { Configuration } from '@/utils/Configuration';
  import { defineComponent } from 'vue';
  
  export default defineComponent({
      name: "UserInfo",
      props: {
        isActive: Boolean,
        userType: {
          type: UserType,
          default: new UserType()
        }
      },
      data: () => ({
        name: '',
        tierName: '',
        upgradeExpiresString: '',
        description: '',
        isActiveLocal: false
      }),
      watch: {
        userType(userType: UserType) {
          if (userType) {
            this.name = userType.name;
            this.tierName =  Tier[userType.tier];
            this.description = userType.description;
            if (userType.upgradeExpires < new Date()) {
              this.upgradeExpiresString = "Expired";
            } else {
              console.log(userType.upgradeExpires);
              this.upgradeExpiresString = userType.upgradeExpires.toLocaleString('default', { month: 'long' }) + ' ' +
                userType.upgradeExpires.getDate() + ', ' + ' ' + userType.upgradeExpires.getFullYear();
            }
          }
        },
        isActive() {
          this.isActiveLocal = this.isActive;
        }
      },
      methods: {
        save() {
          let ret = new UserType();
          ret.name= this.name;
          ret.description = this.description;
          ret.tier = this.userType.tier;
          ret.upgradeExpires = this.userType.upgradeExpires;
          this.$emit('close');
          this.$emit('saveuserinfo', ret);
        },
        upgrade() {
          if (this.userType.tier == 0) {
            this.$emit('subscription', this.userType);
          } else {
            window.open(Configuration.StripeCustomerPortalUrl, '_blank');
          }
        }
      }
  });
  </script>

<style scoped lang="stylus">
.label
  padding-bottom: .5em !important

.field-row
  padding-top: .75em
  padding-bottom: .75em

.field-row a
  cursor pointer

.last-row
  margin-bottom: 1em
</style>