import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26f238df"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "model-editor"
}
const _hoisted_2 = { class: "pa-3 text--secondary" }
const _hoisted_3 = {
  key: 0,
  class: "error--text px-3 text-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_ctx.model)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.hasPixiModel)
          ? (_openBlock(), _createBlock(_component_v_list, {
              key: 0,
              expand: "",
              class: "ptb-12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_group, { value: false }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_list_item, _mergeProps(props, { title: "Display" }), null, 16)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_v_slider, {
                            dense: "",
                            class: "mt-2",
                            "prepend-icon": "mdi-magnify-plus-outline",
                            modelValue: _ctx.displayModelAdjust.scale,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displayModelAdjust.scale) = $event)),
                            messages: String(_ctx.displayModelAdjust.scale),
                            min: "0.01",
                            max: "2",
                            step: "0.01"
                          }, null, 8, ["modelValue", "messages"]),
                          _createVNode(_component_v_slider, {
                            dense: "",
                            class: "mt-2",
                            "prepend-icon": "mdi-arrow-left-right",
                            modelValue: _ctx.displayModelAdjust.posX,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.displayModelAdjust.posX) = $event)),
                            messages: String(_ctx.displayModelAdjust.posX),
                            min: "-1",
                            max: "1",
                            step: "0.01"
                          }, null, 8, ["modelValue", "messages"]),
                          _createVNode(_component_v_slider, {
                            dense: "",
                            class: "mt-2",
                            "prepend-icon": "mdi-arrow-up-down",
                            modelValue: _ctx.displayModelAdjust.posY,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.displayModelAdjust.posY) = $event)),
                            messages: String(_ctx.displayModelAdjust.posY),
                            min: "-1",
                            max: "1",
                            step: "0.01"
                          }, null, 8, ["modelValue", "messages"]),
                          _createVNode(_component_v_slider, {
                            dense: "",
                            class: "mt-2",
                            "prepend-icon": "mdi-rotate-right",
                            modelValue: _ctx.displayModelAdjust.rotation,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.displayModelAdjust.rotation) = $event)),
                            messages: _ctx.rotationDeg,
                            min: "0",
                            max: "6.28",
                            step: "0.01"
                          }, null, 8, ["modelValue", "messages"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("pre", _hoisted_2, _toDisplayString(_ctx.model.loadingState.text), 1),
              (_ctx.model.error)
                ? (_openBlock(), _createElementBlock("pre", _hoisted_3, _toDisplayString(_ctx.model.error), 1))
                : _createCommentVNode("", true)
            ], 64))
      ]))
    : _createCommentVNode("", true)
}