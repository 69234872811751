import { Live2DModel } from '@/app/Live2DModel';
import { Queue } from './Queue';
// import { Vosk } from 'vosk-browser';
const Vosk = require('../../scripts/vosk.js');

export class SpeechController extends EventTarget
{
    private pixiModel: Live2DModel;
    private audioUrlQueue = new Queue<string>();
    private audioContext: AudioContext;
    private mediaStream: MediaStream;
    private speakInterval: number;
    private segmentsRemaining: number;

    constructor() {
        super();
    }

    InitCharacterSpeak(model: Live2DModel) {
      this.pixiModel = model;
      this.speakInterval = window.setInterval(() => {
        if(!this.pixiModel) { // Support character unloading
          clearInterval(this.speakInterval);
        }
        if(!this.IsAudioPlaying() && this.audioUrlQueue.size() > 0) {
          const segment: any = this.audioUrlQueue.remove();
          this.segmentsRemaining = segment.SegmentsRemaining;
          this.dispatchEvent(new CustomEvent('speaking', { detail: { playing: true, remaining: this.segmentsRemaining } }));
          // @ts-expect-error
          this.pixiModel.speak(segment.AudioUrl, { volume: 1, expression: null, resetExpression: true, crossOrigin: 'use-credentials' });
          const currentSpeakId = setInterval(() => {
            if(!this.IsAudioPlaying()) {
              clearInterval(currentSpeakId);
              this.dispatchEvent(new CustomEvent('speaking', { detail: { playing: false, remaining: this.segmentsRemaining } }));
            }
          }, 250);
        }
      }, 250);
    }

    UnloadCharacterSpeak() {
      clearInterval(this.speakInterval);
    }

    async InitRecordAudio() {
      const channel = new MessageChannel();
      const model = await Vosk.createModel('model.tar.gz', -2); // Param 2 is logLevel
      model.registerPort(channel.port1);

      const sampleRate = 16000;
      
      const recognizer = new model.KaldiRecognizer(sampleRate);
      recognizer.setWords(true);
      
      recognizer.on("result", (message) => {
          const text = message.result.text;
          if(text.length > 0 && text != "huh") { // Vosk occasionally hears background noise as "huh"
            //console.log('STT: ' + text);
            //recognizerProcessor.disconnect();
            //source.disconnect();
            //mediaStream.getTracks().forEach(function(track) {
            //  track.stop();
            //});
            this.StopRecordAudio();
            this.dispatchEvent(new CustomEvent('sttResolved', { detail: { text: text } }));
          }
      });
      recognizer.on("partialresult", (message) => {
          // console.log('partialresult: ' + message.result.partial);
      });
      
      this.mediaStream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: {
              echoCancellation: true,
              noiseSuppression: true,
              channelCount: 1,
              sampleRate: sampleRate
          },
      });
      
      this.audioContext = new AudioContext({sampleRate: sampleRate});
      await this.audioContext.audioWorklet.addModule('recognizer-processor.js')
      const recognizerProcessor = new AudioWorkletNode(this.audioContext, 'recognizer-processor', { channelCount: 1, numberOfInputs: 1, numberOfOutputs: 1 });
      recognizerProcessor.port.postMessage({action: 'init', recognizerId: recognizer.id}, [ channel.port2 ])
      recognizerProcessor.connect(this.audioContext.destination);
      
      const source = this.audioContext.createMediaStreamSource(this.mediaStream);
      source.connect(recognizerProcessor);
      this.StopRecordAudio();
      this.dispatchEvent(new CustomEvent('recordingInitialized'));
    }

    InterruptSpeaking() {
      // TODO
      // this.pixiModel.stopSpeaking();
    }

    EnqueueAndPlay(segment: any) {
      this.audioUrlQueue.add(segment);
    }

    IsAudioPlaying(): boolean {
      return this.pixiModel && this.pixiModel.internalModel && this.pixiModel.internalModel.motionManager ? Boolean(this.pixiModel.internalModel.motionManager.currentAudio) : false;
    }

    async StartRecordAudio() {
      if(this.mediaStream && this.mediaStream.getTracks().length > 0) {
        this.mediaStream.getAudioTracks().forEach((track: any) => {
          track.enabled = true;
        });
      }
    }

    StopRecordAudio() {
      if(this.mediaStream && this.mediaStream.getTracks().length > 0) {
        this.mediaStream.getAudioTracks().forEach((track: any) => {
          if (track.readyState == 'live') {
            track.enabled = false;
          }
        });
      }
    }
}
