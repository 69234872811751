<template>
  <v-dialog persistent scrim="#000" v-model="isActiveLocal" width="1500" max-width="90vw">
    <v-card height="1000" max-height="95vh" class="d-flex flex-column">
      <v-toolbar color="primary" flat dense class="flex-grow-0">
        <v-toolbar-title>Select a Character</v-toolbar-title>
        <v-btn icon class="ml-1 menu-close" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row no-gutters class="content-row flex-grow-1" v-show="$vuetify.display.mdAndUp">
        <v-col class="tree-col">
          <v-treeview activatable :items="characters" open-on-click v-model:activated="active" open-strategy="single">
            <template v-slot:prepend="{ item }">
              <v-icon class="folder">
                {{ item.children ? 'mdi-folder' : 'mdi-account' }}
              </v-icon>
              {{ item.name }}
              <v-chip v-if="item.children">{{ item.children.length }}</v-chip>
            </template>
          </v-treeview>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col class="content-col">
          <div class="empty" v-if="!selected">
            No Character Selected
          </div>
          <v-card v-else :key="selected.value" flat class="char-card">
            <v-card-text>
              <v-avatar size="225">
                <v-img :src="getAvatar(selected.value)"></v-img>
              </v-avatar>
              <h3>
                {{ selected.name }}
              </h3>
              <div>
                {{ selected.description }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters class="content-row-sm-container flex-grow-1" v-show="$vuetify.display.smAndDown">
        <v-row class="content-row-sm">
          <v-treeview activatable :items="characters" open-on-click v-model:activated="active" open-strategy="single">
            <template v-slot:prepend="{ item }">
              <v-icon class="folder">
                {{ item.children ? 'mdi-folder' : 'mdi-account' }}
              </v-icon>
              {{ item.name }}
              <v-chip v-if="item.children">{{ item.children.length }}</v-chip>
            </template>
          </v-treeview>
        </v-row>
        <v-divider></v-divider>
        <v-row class="content-row-sm content-col">
          <div class="empty" v-if="!selected">
            No Character Selected
          </div>
          <v-card v-else :key="selected.value" flat class="char-card">
            <v-card-text>
              <v-avatar size="160">
                <v-img :src="getAvatar(selected.value)"></v-img>
              </v-avatar>
              <h3>
                {{ selected.name }}
              </h3>
              <div>
                {{ selected.description }}
              </div>
            </v-card-text>
          </v-card>
        </v-row>
      </v-row>
      <v-divider></v-divider>
      <div class="footer d-flex">
        <v-btn color="blue-grey" @click="$emit('close')">Cancel
          <v-icon class="v-btn-right-icon">mdi-close-circle-outline</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="blue-grey" @click="submit">Select
          <v-icon class="v-btn-right-icon">mdi-checkbox-marked-circle-outline</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import characters from '../data/characters';
export default defineComponent({
    name: "ModelPicker",
    props: {
        isActive: Boolean
    },
    data: () => ({
        characters: characters,
        isActiveLocal: false,
        active: []
    }),
    computed: {
        selected(): any {
            if (!this.active.length) {
              return undefined
            }
            const value = this.active[0];
            return this.findByValue(value);
        }
    },
    watch: {
        isActive() {
          this.isActiveLocal = this.isActive;
        }
    },
    methods: {
        getAvatar(value: string) {
          return value.replace('.json', '.jpg');
        },
        findByValue(value) {
          for (let i = 0; i < this.characters.length; i++) {
            const character = characters[i].children.find(character => character.value === value)
            if (character) return character;
          }
        },
        submit() {
            if (this.selected) {
              this.$emit('select', this.selected.value.replace(/ /g, '%20'));
            }
        }
    }
});
</script>

<style scoped lang="stylus">
.content-row
  overflow auto

.tree-col
  height 100%
  overflow auto

.content-col
  display: flex
  justify-content: center
  align-items: center

.content-row-sm-container
  overflow hidden
  display block

.content-row-sm
  height 50%
  overflow auto
  margin: 0

.content-row-sm.row
  display block
  margin 0;

.content-row-sm.row :deep(.v-list-item)
  display inline-flex !important

.content-row-sm.row :deep(.v-treeview-node__root)
  display inline-flex !important

.content-row-sm.row :deep(.v-treeview-node__label)
  overflow visible !important

.v-list-item__content
  overflow visible !important

.model-count
  vertical-align text-top
  pointer-events none

.folder
  margin-right: 8px

.v-chip.v-chip--size-default
  font-size: .8em
  padding: 0 10px
  --v-chip-height: 0
  margin: 0 0 6px 8px

.empty
  font-size: 1.25rem

.char-card
  text-align: center

.v-card-text h3
  text-align: center
  padding: 16px

.footer
  padding: 12px

.footer span
  display: inline-flex !important
  align-items: center
</style>
