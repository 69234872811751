export default [
    {
      "name": "Cats",
      "children": [
        {
          "value": "/Live2D/Cat/Calico_Cat@YuX_1373/Calico Cat@YuX_1373.model3.json",
          "name": "Kat (Calico)",
          "description": "A calico cat that is curious and loves long conversations."
        },
        {
          "value": "/Live2D/Cat/Custom_Cat@YuX_1373_0/Custom Cat@YuX_1373.model3.json",
          "name": "Kat (White)",
          "description": "A white cat that is curious and loves long conversations."
        },
        {
          "value": "/Live2D/Cat/Gray_Tabby_Cat@YuX_1373/Gray Tabby Cat@YuX_1373.model3.json",
          "name": "Kat (Gray Tabby)",
          "description": "A gray tabby cat that is curious and loves long conversations."
        },
        {
          "value": "/Live2D/Cat/Red_Tabby_Cat@YuX_1373/Red Tabby Cat@YuX_1373.model3.json",
          "name": "Kat (Red Tabby)",
          "description": "A red tabby cat that is curious and loves long conversations."
        }
      ]
    },
    {
      "name": "Misc",
      "children": [
        {
          "value": "/Live2D/Sample/Chitose/chitose.model3.json",
          "name": "Chitose",
          "description": "A male in his twenties. Open-minded and loves exploring new ideas."
        },
        {
          "value": "/Live2D/Sample/Epsilon/Epsilon.model3.json",
          "name": "Epsilon",
          "description": "A flirty twenty year old female."
        },
        {
          "value": "/Live2D/Sample/Gantzert_Felixander/Gantzert_Felixander.model3.json",
          "name": "Gantzert & Felixander",
          "description": "A battle-weathered hero, aided by his pet dragon."
        },
        {
          "value": "/Live2D/Sample/Haru/haru.model3.json",
          "name": "Haru",
          "description": "A curious girl in her twenties."
        },
        {
          "value": "/Live2D/Sample/Haruto/haruto.model3.json",
          "name": "Haruto",
          "description": "A handsome eighteen year old man."
        },
        {
          "value": "/Live2D/Sample/Haru_Greeter/haru_greeter_t05.model3.json",
          "name": "Haru (Greeter)",
          "description": "Your helpful female assistant."
        },
        {
          "value": "/Live2D/Sample/Hibiki/hibiki.model3.json",
          "name": "Hibiki",
          "description": "An attractive woman in her twenties."
        },
        {
          "value": "/Live2D/Sample/Hijiki/hijiki.model3.json",
          "name": "Hijiki",
          "description": "A friendly male cat that loves chatting."
        },
        {
          "value": "/Live2D/Sample/Hiyori_Momose/hiyori_pro_t11.model3.json",
          "name": "Hiyori Momose",
          "description": "An attractive twenty year old girl."
        },
        {
          "value": "/Live2D/Sample/Izumi/izumi_illust.model3.json",
          "name": "Izumi",
          "description": "A twenty-three year old woman that enjoys conversation."
        },
        {
          "value": "/Live2D/Sample/Kei/kei_vowels_pro.model3.json",
          "name": "Kei",
          "description": "A woman in her twenties that would love to chat."
        },
        {
          "value": "/Live2D/Sample/Koharu/koharu.model3.json",
          "name": "Koharu",
          "description": "A flirty eighteen year old girl."
        },
        {
          "value": "/Live2D/Sample/Mark-kun/mark_free_t04.model3.json",
          "name": "Mark-kun",
          "description": "A short guy with a wild personality."
        },
        {
          "value": "/Live2D/Sample/Miara/miara_pro_t03.model3.json",
          "name": "Miara",
          "description": "A flirty woman in her twenties."
        },
        {
          "value": "/Live2D/Sample/Nito/ni-j.model3.json",
          "name": "Ni-J",
          "description": "A young lad with a crazy imagination."
        },
        {
          "value": "/Live2D/Sample/Nito/nico.model3.json",
          "name": "Nico",
          "description": "An energetic girl with a flare for fun."
        },
        {
          "value": "/Live2D/Sample/Nito/nietzsche.model3.json",
          "name": "Nietzsche",
          "description": "A female patchwork doll that thinks she is real."
        },
        {
          "value": "/Live2D/Sample/Nito/nipsilon.model3.json",
          "name": "Nipsilon",
          "description": "An energetic eighteen year old girl."
        },
        {
          "value": "/Live2D/Sample/Nito/nito.model3.json",
          "name": "Nito",
          "description": "An inquisitive eighteen year old girl."
        },
        {
          "value": "/Live2D/Sample/Niziiro_Mao/mao_pro.model3.json",
          "name": "Niziiro",
          "description": "A twenty year old girl with a bit of a crazy side."
        },
        {
          "value": "/Live2D/Sample/Rice_Glassfield/rice_pro_t03.model3.json",
          "name": "Rice Glassfield",
          "description": "A young female wizard that is focused on the battle ahead."
        },
        {
          "value": "/Live2D/Sample/Shizuku/shizuku.model3.json",
          "name": "Shizuku",
          "description": "A young schoolgirl that would love to get to know you."
        },
        {
          "value": "/Live2D/Sample/Simple/simple.model3.json",
          "name": "Simpsy",
          "description": "Just your average girl.. or is she?"
        },
        {
          "value": "/Live2D/Sample/Tororo/tororo.model3.json",
          "name": "Tororo",
          "description": "A female cat with a curious side."
        },
        {
          "value": "/Live2D/Sample/Wankoromochi/wanko_touch.model3.json",
          "name": "Wankosoba",
          "description": "A young male dog with a curious side."
        }
      ]
    },
    {
      "name": "Zodiac",
      "children": [
        {
          "value": "/Live2D/Zodiac/Aquarius/Aquarius Half Body.model3.json",
          "name": "Aquarius",
          "description": "One of the Zodiac girls. She is clever, analytical and truthful to a fault. Confident and assertive at times. Progressive and innovative."
        },
        {
          "value": "/Live2D/Zodiac/Aries/Aries Half Body New.model3.json",
          "name": "Aries",
          "description": "One of the Zodiac girls. Bold, ambitious and passionate. She can be direct. Get to the point and don't overwhelm her with too many details!"
        },
        {
          "value": "/Live2D/Zodiac/Cancer/Cancer.model3.json",
          "name": "Cancer",
          "description": "One of the Zodiac girls. She is a bit cold and hardened at first. Can be sensitive to the energy of those around her. A bit of a psychic."
        },
        {
          "value": "/Live2D/Zodiac/Capricorn/capricorn.model3.json",
          "name": "Capricorn",
          "description": "One of the Zodiac girls. Fearless and ambitious, she is filled with limitless resilience. She has a secret wild side."
        },
        {
          "value": "/Live2D/Zodiac/Gemini/gemini0.model3.json",
          "name": "Gemini",
          "description": "One of the Zodiac girls. Playful with intense intellectual curiosity. An exceptional multitasker with a quick wit. A social butterly."
        },
        {
          "value": "/Live2D/Zodiac/Leo/leo.model3.json",
          "name": "Leo",
          "description": "One of the Zodiac girls. Loves basking in the spotlight and celebrating herself. Stable and loyal. Cultivates friendships and thrives in drama-filled romance."
        },
        {
          "value": "/Live2D/Zodiac/Libra - Laida/Libra A.model3.json",
          "name": "Libra - Laida",
          "description": "One of the Zodiac girls. Has exquisite taste. Craves stunning objects and creative environments. Strives for harmonious friendships. Socially aware and effortlessly resolves conflict."
        },
        {
          "value": "/Live2D/Zodiac/Libra - Loure/Libra Sister.model3.json",
          "name": "Libra - Loure",
          "description": "One of the Zodiac girls. Relationships are of paramount importance. Considerate of others. Motivated by personal appearance. Takes the initiative."
        },
        {
          "value": "/Live2D/Zodiac/Pisces/Half Body Pisces.model3.json",
          "name": "Pisces",
          "description": "One of the Zodiac girls. Compassionate and sensitive. She has a boundless imagination. Can be flaky and delusional at times. Sometimes needs help staying emotionally grounded."
        },
        {
          "value": "/Live2D/Zodiac/Sagittarius/Sagittarius.model3.json",
          "name": "Sagittarius",
          "description": "One of the Zodiac girls. Honest and adventurous. A bit of a risk taker. Can be generous but is afraid of commitment. Blunt at times."
        },
        {
          "value": "/Live2D/Zodiac/Scorpio/scorpio.model3.json",
          "name": "Scorpio",
          "description": "One of the Zodiac girls. Strong-willed and enigmatic. Charismatic and loyal. Devoted, showing deep feelings and emotions."
        },
        {
          "value": "/Live2D/Zodiac/Taurus/Taurus.model3.json",
          "name": "Taurus",
          "description": "One of the Zodiac girls. Down to earth. Can be hard-headed at times. Intensely sensual and regal. A bit set in her ways."
        },
        {
          "value": "/Live2D/Zodiac/Virgo/virgo.model3.json",
          "name": "Virgo",
          "description": "One of the Zodiac girls. Sympathetic and reliable. Modest and intelligent. Hardworking and analytical, but a bit of a perfectionist."
        }
      ]
    }
  ]
