<template>
  <v-app>
    <v-navigation-drawer absolute stateless :width="drawerWidth" v-model="drawer"
      @transitionend="!drawer && (drawerSwitch = true)">
      <v-toolbar color="primary">
        <v-toolbar-title>Gabber Beta</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn-toggle group rounded v-model="tab">
          <v-btn icon v-show="settings">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </v-btn-toggle>
        <v-btn icon class="ml-1 menu-close" @click="showUI(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div v-show="tab === undefined" class="model-page">
        <div class="header pa-3" v-show="selectedModelId">
          <div class="d-flex align-center chat-management-row">
            <div class="text-h6">Chat Management</div>
            <v-spacer></v-spacer>
            <v-btn icon width="48" height="48" class="mr-n3" @click="showChatInfoDialog()">
              <v-icon>mdi-information-box</v-icon>
            </v-btn>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="header" v-if="showAddCharacter">
          <div class="d-flex align-center ptb-6 add-char-row" :class="{ 'add-char-row-none': selectedModelId == 0 }"
            v-if="selectedModelId == 0">
            <div class="text-h6">Add a character</div>
            <v-spacer></v-spacer>
            <v-btn icon width="48" height="48" class="mr-n3" @click="create()" elevation="0">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <v-divider v-show="selectedModelId"></v-divider>
          <div class="d-flex align-center add-char-divider" :class="{ 'add-char-divider-none': selectedModelId == 0 }">
            <div class="model-name text-h6" v-show="selectedModelId">
              {{ selectedModelId ? displayModel.name : '' }}
            </div>
            <v-spacer v-show="selectedModelId"></v-spacer>
            <v-btn icon width="48" height="48" class="mr-n3" @click="removeCharacter()" v-show="selectedModelId">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </div>
        </div>

        <v-divider></v-divider>

        <ModelEditor :id="selectedModelId" :displayModel="displayModel" :visible="drawer && tab === undefined"
          :messageSegment="messageSegment" @characterLoaded="characterLoaded($event)" @speaking="speakToggle($event)" />
      </div>

      <ModelSettings v-show="tab === 0" />

      <v-divider v-show="selectedModelId"></v-divider>

      <VisionToggle v-show="selectedModelId" @select="cameraToggle($event)" />

      <v-divider v-show="selectedModelId"></v-divider>

      <div class="header pa-3">
        <div class="d-flex align-center ptb-6 user-info-row">
          <div class="text-h6">User Info</div>
          <v-spacer></v-spacer>
          <v-btn icon width="48" height="48" class="mr-n3" @click="showUserInfoDialog()" elevation="0">
            <v-icon>mdi-information-box</v-icon>
          </v-btn>
        </div>
      </div>

      <v-divider></v-divider>

      <LogOut />
    </v-navigation-drawer>
    <v-main>
      <v-container fluid class="pa-0 fill-height flex-column">
        <v-spacer></v-spacer>
        <ModelList v-model="selectedModelId" :show="modelList.visible" />
      </v-container>
      <ModelCreation :isActive="modelCreationDialog" @close="modelCreationDialog = false" @create="modelSelected($event)" />
      <ModelInfo v-model="modelInfoDialog" :id="selectedModelId" />
      <ChatInfo :isActive="chatInfoDialog" @close="chatInfoDialog = false" :chatHistory="chatHistory" @newchat="newChat()" />
      <UserInfo :isActive="userInfoDialog" @close="userInfoDialog = false" :userType="userType"
        @saveuserinfo="saveUserInfo($event)" @subscription="showSubscriptionDialog()" />
      <SubscriptionInfo v-model="subscriptionDialog" :subscriptionType="subscriptionType"
        @refreshsubscription="refreshSubscription()" />
    </v-main>

    <v-fab-transition>
      <VBtn icon="mdi-menu-close" size="large" color="accent" elevation="8" position="absolute"
        class="menu" @click="showUI(true)" v-show="drawerSwitch" :disabled="!isConnected || isSpeaking" />
    </v-fab-transition>

    <div v-show="selectedModelId" :class="{ 'last-message-off': !enableLastMessage }" class="last-message">{{ lastMessage
      }}</div>
    <div class="text-message" v-show="selectedModelId" :class="{ 'text-message-mobile': textMessageMobile }">
      <VBtn color="accent" @click="toggleLastMessage()" :disabled="!(isConnected && introDone && selectedModelId && !isSpeaking)" 
        v-show="!enableLastMessage" icon="mdi-message-plus-outline" size="large" elevation="8" />
      <VBtn color="accent" @click="toggleLastMessage()" :disabled="!(isConnected && introDone && selectedModelId && !isSpeaking)" 
        v-show="enableLastMessage" icon="mdi-message-minus-outline" size="large" elevation="8" />
      <v-spacer></v-spacer>
      <textarea id="textmessage" v-model="textMessage" placeholder="Type a response" maxlength="500"
        :disabled="!(isConnected && introDone && selectedModelId && !isSpeaking)" v-on:keyup.enter="sendTextMessage()"
        @focus="textMessageFocus(true)" @blur="textMessageFocus(false)"></textarea>
      <v-spacer></v-spacer>
      <VBtn color="accent" @click="sendTextMessage()" :disabled="!(isConnected && introDone && selectedModelId && !isSpeaking)" 
        icon="mdi-send-circle-outline" size="large" elevation="8" />
    </div>

    <VBtn icon="mdi-camera-flip-outline" size="large" color="accent" elevation="8" position="fixed"
      class="video-flip" @click="visionFlip()" v-show="selectedModelId && visionIsCamera"
      :disabled="!(isConnected && introDone && selectedModelId && !isSpeaking)" />
    <VBtn icon="mdi-camera-outline" size="large" color="accent" elevation="8"
      position="fixed" class="camera-button" @click="visionSelect()" v-show="selectedModelId && visionIsCamera"
      :disabled="!(isConnected && introDone && selectedModelId && !isSpeaking)" />
    <VBtn icon="mdi-image-outline" size="large" color="accent" elevation="8"
      position="fixed" class="image-button" @click="visionSelect()" v-show="selectedModelId && !visionIsCamera"
      :disabled="!(isConnected && introDone && selectedModelId && !isSpeaking)" />

    <video id="video" class="video" v-show="selectedModelId && visionIsCamera"></video>
    <img id="photo" class="photo" v-show="visionResponding" />
    <canvas id="camcanvas" class="camcanvas"></canvas>
    <input id="fileinput" type="file" class="file-input" />

    <v-btn class="microphone"
      :class="[{ 'mic-on': isConnected && introDone && selectedModelId && micEnable && !isSpeaking && !userMuteEnabled }, { 'mic-off': isConnected && introDone && selectedModelId && micEnable && !isSpeaking && userMuteEnabled }]"
      @click="muteToggle()">
      <v-icon v-if="isConnected && micEnable && !isSpeaking && !userMuteEnabled">mdi-microphone</v-icon>
      <v-icon v-if="!isConnected || !micEnable || isSpeaking || userMuteEnabled">mdi-microphone-off</v-icon>
    </v-btn>

    <v-snackbar v-model="snackbar.visible" :timeout="snackbar.timeout">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar.visible = false"><v-icon>mdi-close</v-icon></v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ModelList from './components/ModelList.vue';
import ModelCreation from './components/ModelCreation.vue';
import ModelEditor from '@/components/ModelEditor.vue';
import ModelSettings from '@/components/ModelSettings.vue';
import VisionToggle from '@/components/VisionToggle.vue';
import LogOut from '@/components/LogOut.vue';
import ChatInfo from '@/components/ChatInfo.vue';
import UserInfo from '@/components/UserInfo.vue';
import ModelInfo from '@/components/ModelInfo.vue';
import SubscriptionInfo from '@/components/SubscriptionInfo.vue';
import { Background } from '@/tools/Background';
import { App } from '@/app/App';
import { SpeechController } from '@/app/SpeechController';
import { MessageSegment } from '@/app/MessageSegment';
import { Live2DModel } from '@/app/Live2DModel';
import { DisplayModel } from '@/app/DisplayModel';
import { Configuration } from './utils/Configuration';
import { RedirectLoginOptions, createAuth0Client } from '@auth0/auth0-spa-js';
import { StorageKeys } from './utils/StorageKeys';
import { UserType } from './data/UserType';
import { SubscriptionType } from './data/SubscriptionType';
import getStripe from './utils/getStripe';
import { useDisplay } from 'vuetify/lib/framework.mjs';

export default defineComponent({
  name: 'App',
  components: { ModelList, ModelCreation, ModelEditor, ModelInfo, ModelSettings, VisionToggle, ChatInfo, LogOut, UserInfo, SubscriptionInfo },
  data: () => ({
    drawer: false,
    drawerSwitch: true,
    loading: false,
    micEnable: false,
    isSpeaking: false,
    introDone: false,
    settings: false,

    tab: -1 as number | undefined,

    modelList: {
      visible: false,
    },

    selectedModelId: 0,

    modelInfoDialog: false,
    chatInfoDialog: false,
    userInfoDialog: false,
    subscriptionDialog: false,
    modelCreationDialog: false,

    snackbar: {
      visible: false,
      message: '',
      timeout: 5000,
    },
    ws: null as WebSocket | null | undefined,
    userId: '',
    sessionId: '',
    chatId: '',
    ready: false,
    userMessage: '',
    characterCanSpeak: true,
    speechController: new SpeechController(),
    autoSendRecognizedSpeech: true,
    authenticated: false,
    visionResponding: false,
    visionIsCamera: false,
    cameraAvailable: false,
    cameraFacingMode: 'environment',
    videoWidth: 320,
    videoHeight: 0,
    mediaStream: null as MediaStream | null | undefined,
    chatHistory: null,
    userType: null as UserType | null | undefined,
    textMessage: '',
    textMessageMobile: false,
    displayModel: new DisplayModel(),
    isConnected: false,
    unloadedCharacter: false,
    userMuteEnabled: false,
    authFail: false,
    enableLastMessage: true,
    lastMessage: '',
    lastMessageWasText: false,
    accountTier: 0,
    subscriptionType: null as SubscriptionType | null | undefined,
    showAddCharacter: false,
    messageSegment: null as MessageSegment | null | undefined,
    isMobile: false // Bandaid for useDisplay() in textMessageFocus
  }),
  computed: {
    drawerWidth(): number {
      return useDisplay().xl ? 450 : 360;
    },
    modelName(): string {
      return App.getModel(this.selectedModelId)?.name || '';
    }
  },
  methods: {
    webServiceConnect() {
      // Create WebSocket connection to Server
      this.ws = new WebSocket(Configuration.WebSocketUrl);

      this.ws.onopen = () => {
        console.log('Connected to server.');
        const intervalId = setInterval(() => {
          if (this.ws?.readyState) { // Wait until socket is ready
            clearInterval(intervalId);
            this.isConnected = true;
            this.snackbar.visible = false;
            const handshakeRequest =
            {
              $type: 'initialize',
              client: 'Web.Gabber',
              clientVersion: '0.1',
              sessionId: this.sessionId
            }
            this.ws?.send(JSON.stringify(handshakeRequest));
          }
        }, 100);
      }

      this.ws.onclose = async () => {
        console.log('Disconnected from server.');
        if (!this.authFail) {
          if (this.isConnected) {
            this.isConnected = false;
            this.drawer = false;
            this.snack('Lost connection to server. Reconnecting...', 999999999);
          }
          // Re-establish connection (we should never be in a disconnected state)
          setTimeout(() => {
            console.log('Trying to reconnect...');
            this.webServiceConnect();
          }, 5000);
        }
      }

      this.ws.onerror = (event) => {
        console.error(event);
        this.snackbar.visible = true;
      }

      this.ws.onmessage = async (messageEvent: MessageEvent) => {
        const message = JSON.parse(messageEvent.data);
        console.log(`Received message from server: ${message.$type}`);
        switch (message.$type) {
          case '401':
            {
              this.authFail = true;
              const auth0Config = {
                "domain": Configuration.Auth0Domain,
                "clientId": Configuration.Auth0ClientId
              }
              const auth0RedirectConfig: RedirectLoginOptions = {
                authorizationParams: {
                  redirect_uri: Configuration.Auth0RedirectUri
                }
              }
              const auth0Client = await createAuth0Client(auth0Config);
              await auth0Client.loginWithRedirect(auth0RedirectConfig);
              break;
            }
          case '403':
            {
              this.removeCharacter(false);
              this.showUI(false);
              this.showUserInfoDialog();
              break;
            }
          case 'welcome':
            {
              this.authenticated = true;
              this.userId = message.userId;
              this.accountTier = message.accountTier;
              this.sessionId = message.sessionId;
              this.isSpeaking = false;
              this.snackbar.visible = false;
              if (message.accountTier == 0 || message.name.length == 0 || message.description.length == 0) {
                this.showUserInfoDialog();
              } else {
                this.showAddCharacter = true;
                this.modelCreationDialog = true;
              }
              break;
            }
          case 'charactersListLoaded':
            {
              // TODO

              break;
            }
          case 'characterLoaded':
            {
              Background.setFromUrl(message.character.background);
              this.modelCreationDialog = false;
              this.displayModel = new DisplayModel(
                message.character.pos_x,
                message.character.pos_y,
                message.character.scale,
                message.character.rotation,
                message.character.name
              );
              this.startChat();
              break;
            }
          case 'chatInProgress':
            {
              console.error('Server: There is already a chat in progress');
              break;
            }
          case 'chatStarted':
            {
              this.chatId = message.chatId;
              this.chatHistory = null;
              this.userType = null;
              this.ready = true;
              break;
            }
          case 'chatClosed':
            {
              this.sessionId = '';
              this.ready = false;
              break;
            }
          case 'replyStart':
            {
              //let messageId = message.messageId;
              //console.log('Received reply start: ' + messageId);
              this.lastMessage = '';
              break;
            }
          case 'replySegment':
            {
              let sessionId = message.sessionId;
              let messageId = message.messageId;
              let text = message.text;
              let segment = new MessageSegment();
              segment.SessionId = sessionId;
              segment.MessageId = messageId;
              segment.Text = text;
              segment.AudioUrl = message.audioUrl;
              segment.AudioBuffer = message.audioBuffer;
              console.log('-- ' + message.segmentsRemaining);
              segment.SegmentsRemaining = message.segmentsRemaining;
              console.log('Receive reply segment: ' + messageId + ' ' + text);
              if (this.lastMessage.length > 0) {
                this.lastMessage += ' ';
              }
              this.lastMessage += segment.Text;
              if (segment.AudioUrl && segment.AudioUrl != '') {
                this.messageSegment = segment;
              } else if (segment.AudioBuffer) {
                // @ts-expect-error
                const array = new Uint8Array(segment.AudioBuffer.data);
                const audioBlob = new Blob([array], { type: "audio/mpeg" });
                const urlObj = URL.createObjectURL(audioBlob);
                segment.AudioUrl = urlObj;
                this.messageSegment = segment;
              } else {
                console.warn('Segment missing audio.');
              }
              break;
            }
          case 'replyEnd':
            {
              //let messageId = message.messageId;
              //console.log('Received reply end: ' + messageId);
              break;
            }
          case 'history':
            {
              this.chatHistory = message.chatHistory;
              break;
            }
          case 'userInfo':
            {
              let userType = new UserType();
              userType.name = message.name;
              userType.tier = message.tier;
              userType.upgradeExpires = new Date(Date.parse(message.upgradeExpires));
              userType.description = message.description;
              this.userType = userType;
              break;
            }
          case 'userInfoSaved': {
            if (message.success) {
              this.userInfoDialog = false;
              this.showAddCharacter = true;
              if (this.selectedModelId == 0) {
                this.create();
              }
            } else {
              this.snack('Sorry, service error encountered.');
              console.error('Error saving user data');
            }
            break;
          }
          case 'speechRecognitionStart':
            {
              this.speechController.InterruptSpeaking();
              break;
            }
          case 'speechRecognitionEnd':
            {
              let finalText = message.text;
              if (finalText != null && finalText != '' && finalText != 'null') {
                this.userMessage = finalText;
                if (this.autoSendRecognizedSpeech) {
                  return;
                }
                this.SendChatMessage(finalText); //, this.characterCanSpeak, true);
              }
              break;
            }
          case 'subscriptionRefreshed': {
            this.accountTier = message.tier;
            if (this.accountTier > 0) {
              this.showAddCharacter = true;
            }
            break;
          }
          case 'error': {
            this.snack('Sorry, service error encountered.');
            console.error('Server error response');
            break;
          }
          default:
            {
              this.snack('Unexpected server response.');
              console.error('Unknown message type: "' + message + '", maybe the client version is out of date?');
              console.log(message);
              break;
            }
        }
      }
    },
    showUI(show: boolean) {
      this.drawer = show;
      //this.modelList.visible = show;
      this.drawerSwitch = false;
      this.chatInfoDialog = false;
      this.userInfoDialog = false;
      this.subscriptionDialog = false;
    },
    snack(message: string, timeout: number = 5000) {
      this.snackbar.message = message;
      this.snackbar.timeout = timeout;
      this.snackbar.visible = true;
    },
    muteToggle() {
      if (this.micEnable) {
        this.userMuteEnabled = !this.userMuteEnabled;
        localStorage.setItem(StorageKeys.UserMuteEnabled, this.userMuteEnabled?.toString());
        this.userMuteEnabled ? this.speechController.StopRecordAudio() : this.speechController.StartRecordAudio();
      }
    },
    toggleLastMessage() {
      this.enableLastMessage = !this.enableLastMessage;
    },
    create() {
      this.drawer = false;
      this.modelCreationDialog = true;
    },
    modelSelected(modelId: number) {
      this.selectedModelId = modelId;
    },
    removeCharacter(showCreate = true) {
      //eventBus.$emit('unloadCharacter', this.selectedModelId);
      this.speechController.StopRecordAudio();
      this.lastMessage = '';
      this.chatId = '';
      this.chatHistory = null;
      this.userType = null;
      this.selectedModelId = 0;
      this.introDone = false;
      this.unloadedCharacter = true;
      Background.loadBackground();
      if (showCreate) {
        this.create();
      }
    },
    speakToggle(speechData: any) {
      if (speechData.remaining == 0) {
        this.introDone = true;
        setTimeout(() => {
          this.visionResponding = false;
        }, 2500);
        this.isSpeaking = speechData.playing || speechData.remaining > 0;
        this.micEnable = !this.isSpeaking && speechData.remaining == 0;
        if (this.micEnable && !this.userMuteEnabled) {
          this.speechController.StartRecordAudio();
        }
        if (this.lastMessageWasText) {
          setTimeout(() => {
            //document.getElementById('textmessage')?.`focus`();
            document.getElementById('textmessage').focus();
          });
        }
      }
    },
    characterLoaded(model: Live2DModel) {
      // TODO: Hacked: Directly loading character for now
      const loadCharacterRequest = {
        $type: 'loadCharacter',
        sessionId: this.sessionId,
        characterId: model.internalModel.settings.url
      }
      this.ws?.send(JSON.stringify(loadCharacterRequest));
    },
    startChat() {
      //console.log('Start chat');
      const startChatRequest = {
        $type: 'startChat',
        sessionId: this.sessionId
      }
      this.ws?.send(JSON.stringify(startChatRequest));
    },
    IsSessionValid() {
      return (this.sessionId && this.sessionId != '');
    },
    SendChatMessage(text: string) {
      if (!this.IsSessionValid()) return;
      console.log('Sending Message: ' + text);
      const chatRequest = {
        $type: 'send',
        sessionId: this.sessionId,
        text: text
      }
      this.ws?.send(JSON.stringify(chatRequest));
    },
    cameraToggle(selection: string) {
      this.visionIsCamera = selection == 'camera';
      if (this.visionIsCamera) {
        this.cameraOn();
      } else {
        this.cameraOff();
      }
    },
    SendVision(imageUrl: string, content: string) {
      console.log("Send vision");
      this.visionResponding = true;
      this.speechController.StopRecordAudio();
      this.isSpeaking = true;
      // Generate vision response
      let request = {
        $type: 'send',
        sessionId: this.sessionId,
        text: content,
        imageUrl: imageUrl
      }
      this.ws?.send(JSON.stringify(request));
    },
    visionFlip() {
      this.cameraFacingMode = this.cameraFacingMode == 'user' ? 'environment' : 'user';
      this.cameraOff();
      this.cameraOn();
    },
    visionSelect() {
      if (this.visionIsCamera) {
        if (navigator.mediaDevices.getUserMedia) { // || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia) {
          const canvas = (document.getElementById('camcanvas') as HTMLCanvasElement);
          const context = canvas?.getContext("2d");
          const photo = document.getElementById('photo');
          const video: any = document.getElementById('video');
          if (this.videoWidth && this.videoHeight) {
            canvas.width = this.videoWidth;
            canvas.height = this.videoHeight;
            context.drawImage(video, 0, 0, this.videoWidth, this.videoHeight);
          } else {
            const context = canvas?.getContext("2d");
            context.fillStyle = "#AAA";
            context.fillRect(0, 0, canvas.width, canvas.height);
          }
          const data = canvas?.toDataURL("image/png");
          photo?.setAttribute("src", data);
          this.SendVision(data, "What is this image?");
        } else {
          console.log("Device does not support getUserMedia."); // TODO: Show message or hide button
        }
      } else {
        const input = document.getElementById("fileinput");
        input?.click();
      }
    },
    cameraOff() {
      const video: any = document.getElementById('video');
      this.mediaStream.getVideoTracks().forEach((track: any) => {
        track.enabled = false;
        track.stop();
      });
      video.srcObject = null;
    },
    cameraOn() {
      // Initialize camera
      if (navigator.mediaDevices.getUserMedia) { // || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia) {
        let streaming = false;
        navigator.mediaDevices.getUserMedia({ video: { facingMode: this.cameraFacingMode } }).then((stream) => { // audio: true
          const video: any = document.getElementById('video');
          const canvas = document.getElementById('camcanvas');
          this.mediaStream = stream;
          video.srcObject = stream;
          video.play();
          video.addEventListener(
            "canplay",
            () => {
              if (!streaming) {
                this.videoHeight = (video.videoHeight / video.videoWidth) * this.videoWidth;
                video.setAttribute("width", this.videoWidth);
                video.setAttribute("height", this.videoHeight);
                canvas?.setAttribute("width", this.videoWidth.toString());
                canvas?.setAttribute("height", this.videoHeight.toString());
                streaming = true;
                this.cameraAvailable = true;
              }
            },
            false
          );
        });
      } else {
        console.log("Device does not support getUserMedia.");
      }
    },
    newChat() {
      //console.log("New chat");
      this.showUI(false);
      this.speechController.StopRecordAudio();
      this.introDone = false;
      const startChatRequest = {
        $type: 'startChat',
        sessionId: this.sessionId,
        newChat: true
      }
      this.ws?.send(JSON.stringify(startChatRequest));
    },
    showChatInfoDialog() {
      this.chatInfoDialog = true;
      const historyRequest = {
        $type: 'history',
        sessionId: this.sessionId,
        chatId: this.chatId
      }
      this.ws?.send(JSON.stringify(historyRequest));
    },
    showUserInfoDialog() {
      this.userInfoDialog = true;
      const userInfoRequest = {
        $type: 'userInfo',
        sessionId: this.sessionId
      }
      this.ws?.send(JSON.stringify(userInfoRequest));
    },
    showSubscriptionDialog() {
      //this.subscriptionType.userId = this.userId;
      //this.subscriptionType.accountTier = this.accountTier;
      this.subscriptionType = {
        userId: this.userId,
        accountTier: this.accountTier
      };
      this.userInfoDialog = false;
      this.subscriptionDialog = true;
    },
    refreshSubscription() {
      const refreshRequest = {
        $type: 'refreshSubscription',
        sessionId: this.sessionId
      }
      this.ws?.send(JSON.stringify(refreshRequest));
    },
    sendTextMessage() {
      if (this.textMessage.length > 0) {
        this.speechController.StopRecordAudio();
        this.isSpeaking = true;
        this.lastMessageWasText = true;
        const textMessageRequest = {
          $type: 'send',
          sessionId: this.sessionId,
          text: this.textMessage
        }
        this.textMessage = '';
        this.ws?.send(JSON.stringify(textMessageRequest));
      }
    },
    textMessageFocus(isFocused: boolean) {
      if (this.isMobile) { // Bandaid.. useDisplay() can't be called here without setup
        this.textMessageMobile = isFocused;
      }
    },
    saveUserInfo(userType: UserType) {
      this.userType.name = userType.name;
      this.userType.description = userType.description;
      const request = {
        $type: 'saveUserInfo',
        name: userType.name,
        description: userType.description,
        sessionId: this.sessionId
      }
      this.ws?.send(JSON.stringify(request));
    },
    error(e: any) {
      const message = e && e.message || e + '';
      if (message) {
        this.snack(message, -1);
      }
    },
    log(...args: any[]) {
      console.log(...args);
    }
  },
  async created() {
    await getStripe(); // Preload Stripe for 
    this.isSpeaking = true; // Disable buttons until server connection
    this.userMuteEnabled = localStorage.getItem(StorageKeys.UserMuteEnabled) === "true" || false;
    Background.loadBackground();
    // switch to the default tab
    this.tab = undefined;
    // Set up speech recognition
    this.speechController.InitRecordAudio();
    this.speechController.addEventListener('sttResolved', (e: any) => {
      if (this.IsSessionValid()) {
        this.isSpeaking = true;
        this.lastMessageWasText = false;
        const text: string = e.detail.text;
        console.log('Speech resolved: ' + text);
        const sstResolvedRequest = {
          $type: 'send',
          sessionId: this.sessionId,
          text: text
        }
        this.ws?.send(JSON.stringify(sstResolvedRequest));
      }
    });
    this.webServiceConnect();
  },
  mounted() {
    this.isMobile = useDisplay().xs.value;
    // Handle image selection to base64
    const fileInput = document.getElementById("fileinput");
    fileInput?.addEventListener('change', (event: any) => {
      const selectedfile = event.target.files;
      if (selectedfile.length > 0) {
        const [imageFile] = selectedfile;
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const srcData = (fileReader.result as string);
          const photo = document.getElementById('photo');
          photo?.setAttribute("src", srcData);
          this.SendVision(srcData, "What is this image?");
        };
        fileReader.readAsDataURL(imageFile);
      }
    });
  }
});
</script>

<style scoped lang="stylus">
:deep(.v-navigation-drawer__content)
  display flex
  flex-direction column

.v-toolbar
  flex-grow initial !important

.menu-close
  margin-inline-end: 0 !important

:deep(.v-toolbar__content)
  padding-left 12px
  padding-right 12px

.v-btn-toggle > .v-btn.v-btn
  opacity 1

.model-page
  display flex
  flex-direction column
  overflow auto

.model-name.valid
  cursor pointer
  transition color .1s ease-out

&:hover
  color var(--v-primary-base)

.model-editor
  overflow auto

</style>

<style lang="stylus">
*
  margin: 0
  padding: 0
  box-sizing: border-box

html
  overflow: hidden !important

body
  background-size cover

body.default-bg
  background-size 100% 100% !important

#canvas
  position: absolute
  width: 100% !important

#app
  pointer-events none

.v-application
  background none !important

.v-btn
  pointer-events auto

.v-btn.menu
  top: 16px
  left: 16px

.v-snack
  position: fixed
  bottom: 15.5em

.microphone
  position: fixed
  bottom: 0
  width: 100%

.mic-off
  pointer-events: auto
  background-color: indianred !important
  
.mic-on
  pointer-events: auto
  background-color: lime !important;

.v-input--selection-controls__input
  margin-right: 0
  margin-left: 8px

.v-label
  display: block
  flex: 1

.add-char-row
  padding 12px 24px

.add-char-row-none
  padding-bottom 0 !important

.add-char-divider
  padding: 12px 24px

.add-char-divider-none
  padding: 0 12px 12px 12px !important

.chat-management-row
  padding 0 12px

.user-info-row
  padding 0 12px

.video
  border: 1px solid black
  box-shadow: 2px px 3px black
  width: 20%
  height: auto
  position: absolute
  right: 0
  top: 5.5em
  z-index: 1

.photo
  border: 1px solid lime
  box-shadow: 2px px 3px black
  width: 20%
  height: auto
  position: absolute
  right: 0
  top: 5.5em
  z-index: 2

.video-flip
.camera-button
.image-button
  top: 16px
  right: 16px

.v-btn--disabled.video-flip
.v-btn--disabled.camera-button
.v-btn--disabled.image-button
.text-message .v-btn--disabled
button.menu.v-btn--disabled
  background-color: transparent !important
  color: rgba(255, 255, 255, 0.3) !important

.video-flip
  right: 96px

.camcanvas
  display: none

.file-input
  display: none

.ptb-0
  padding-top: 0px !important
  padding-bottom: 0px !important

.text-message
  pointer-events: auto
  align: center
  position: fixed
  bottom: 4em
  left: 1em
  right: 1em
  display: flex

.text-message-mobile
  bottom: auto !important
  top: 7em

.text-message textarea
  opacity: .8
  background-color: #000 !important
  color: #fff !important
  width: 100%
  padding: .25em
  margin: 0 16px

.text-message .spacer
  width: 1em

.last-message
  pointer-events: auto
  opacity: .8
  transition: opacity .25s linear;
  background-color: #000 !important
  color: #fff !important
  position: fixed
  bottom: 9em
  left: 5.5em
  right: 5.5em
  height: 3.5em
  padding: .25em
  overflow: auto
  -ms-overflow-style: none
  scrollbar-width: none

.last-message::-webkit-scrollbar
  display: none

.last-message-off
  opacity: 0

.v-snackbar--bottom
  bottom: 15em !important

.v-snackbar--variant-elevated
  color: #fff !important
  background-color: #343333 !important
</style>
