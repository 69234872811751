export class Queue<T> {
    private storage: T[] = [];
  
    constructor(private capacity: number = Infinity) {}
  
    // Add item to queue
    add(item: T): void {
      if (this.size() === this.capacity) {
        throw Error("Queue has reached max capacity, you cannot add more items.");
      }
      this.storage.push(item);
    }

    // Returns the first item of the queue without removing it
    peek(): T | undefined {
        if(this.size() > 0) {
            return this.storage[this.size() - 1];
        } else {
            return null;
        }
    }

    // Removes the first item of the queue and returns it, if empty returns null
    poll(): T | undefined {
        if(this.size() > 0) {
            return this.storage.shift();
        } else {
            return null;
        }
    }
    
    // Removes the first item of the queue and returns it
    remove(): T | undefined {
      return this.storage.shift();
    }
    
    // Returns queue size
    size(): number {
      return this.storage.length;
    }
}