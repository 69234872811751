import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as labsComponents from 'vuetify/labs/components';
import * as directives from 'vuetify/directives';
import colors from 'vuetify/lib/util/colors.mjs';

export default createVuetify({
    theme: {
        defaultTheme: 'custom',
        themes: {
            light: {
                colors: {
                  primary: colors.blue.darken2,
                  secondary: colors.grey.darken3,
                  accent: colors.blue.accent1,
                  error: colors.red.accent2,
                  info: colors.blue.base,
                  success: colors.green.base,
                  warning: colors.orange.darken1,
                }
            },
            dark: {
                colors: {
                  primary: colors.blue.base,
                  secondary: colors.grey.darken3,
                  accent: colors.pink.accent1,
                  error: colors.red.accent2,
                  info: colors.blue.base,
                  success: colors.green.base,
                  warning: colors.orange.darken1,
                }
            },
            custom: {
                dark: true,
                colors: {
                    primary: colors.blue.base,
                    secondary: colors.grey.darken3,
                    accent: '#ff4081',
                    error: colors.red.accent2,
                    info: colors.blue.base,
                    success: colors.green.base,
                    warning: colors.orange.darken1,
                }
            }
        },
    },
    components: {
        ...components,
        ...labsComponents
    },
    directives
});
