<template>
    <v-list class="ptb-12">
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Vision"></v-list-item>
        </template>
        <v-list-item>
          <div class="flex flex-wrap gap-3">
            <v-radio-group v-model="selection" hide-details>
              <v-radio label="Camera" value="camera"></v-radio>
              <v-radio label="Image" value="image"></v-radio>
            </v-radio-group>
          </div>
        </v-list-item>
      </v-list-group>
    </v-list>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
      name: "VisionToggle",
      data: () => ({
        selection: 'image'
      }),
      watch: {
        selection() {
            this.$emit('select', this.selection);
        }
    }
  });
  </script>
  
  <style scoped lang="stylus">
  .ptb-12
    padding 12px 0

  .v-list-item
    padding: 12px 24px !important
 </style>
  