import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toHandlers as _toHandlers, mergeProps as _mergeProps, TransitionGroup as _TransitionGroup, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4894a34a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "model-item-title text-truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_item = _resolveComponent("v-item")!
  const _component_v_item_group = _resolveComponent("v-item-group")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_slide_y_reverse_transition = _resolveComponent("v-slide-y-reverse-transition")!

  return (_openBlock(), _createBlock(_component_v_slide_y_reverse_transition, null, {
    default: _withCtx(() => [
      (_ctx.show&&_ctx.models.length)
        ? (_openBlock(), _createBlock(_component_v_sheet, {
            key: 0,
            class: "model-list",
            width: "100%"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_item_group, {
                mandatory: "",
                class: "flex-grow-1",
                value: _ctx.selectedIndex,
                onChange: _ctx.select
              }, {
                default: _withCtx(() => [
                  _createVNode(_TransitionGroup, {
                    class: "model-group d-flex pa-1 pa-xl-2",
                    name: "move"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.models, (model) => {
                        return (_openBlock(), _createBlock(_component_v_item, {
                          key: model.id
                        }, {
                          default: _withCtx(({ active, toggle }) => [
                            _createVNode(_component_v_card, {
                              color: model.error?'#631f1f':active?'grey darken-2':'grey darken-3',
                              class: "ma-1 ma-xl-2",
                              onClick: toggle
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_tooltip, {
                                  top: "",
                                  disabled: !model.error
                                }, {
                                  activator: _withCtx(({ on, attrs }) => [
                                    _createVNode(_component_v_img, _mergeProps({
                                      src: model.thumbnail,
                                      width: model.error?_ctx.paneHeight:model.aspectRatio*_ctx.paneHeight,
                                      height: _ctx.paneHeight,
                                      ref_for: true
                                    }, attrs, _toHandlers(on)), {
                                      placeholder: _withCtx(() => [
                                        _createVNode(_component_v_row, {
                                          class: "fill-height ma-0",
                                          align: "center",
                                          justify: "center"
                                        }, {
                                          default: _withCtx(() => [
                                            (!model.error)
                                              ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                                                  key: 0,
                                                  indeterminate: "",
                                                  color: "grey lighten-5"
                                                }))
                                              : (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode("mdi-alert-circle")
                                                  ]),
                                                  _: 1
                                                }))
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_card_title, { class: "ml-1 pa-0 flex-nowrap text-subtitle-2 text-xl-subtitle-1" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("span", _hoisted_1, _toDisplayString('#' + model.id + ' ' + model.name), 1),
                                            _createVNode(_component_v_spacer),
                                            active
                                              ? (_openBlock(), _createBlock(_component_v_btn, {
                                                  key: 0,
                                                  icon: "",
                                                  onClick: _withModifiers(($event: any) => (_ctx.remove(model.id)), ["stop"])
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_icon, { size: "20" }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode("mdi-close")
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 2
                                                }, 1032, ["onClick"]))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1040, ["src", "width", "height"])
                                  ]),
                                  default: _withCtx(() => [
                                    _createTextVNode(" " + _toDisplayString(model.error), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["disabled"])
                              ]),
                              _: 2
                            }, 1032, ["color", "onClick"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value", "onChange"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}