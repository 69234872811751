<template>
  <div class="model-editor" v-if="model">
    <v-list expand v-if="hasPixiModel" class="ptb-12">
      <v-list-group :value="false">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Display"></v-list-item>
        </template>
        <v-list-item>
          <div>
            <v-slider dense class="mt-2" prepend-icon="mdi-magnify-plus-outline" v-model="displayModelAdjust.scale"
                :messages="String(displayModelAdjust.scale)" min="0.01" max="2" step="0.01"></v-slider>
            <v-slider dense class="mt-2" prepend-icon="mdi-arrow-left-right" v-model="displayModelAdjust.posX"
                :messages="String(displayModelAdjust.posX)" min="-1" max="1" step="0.01"></v-slider>
            <v-slider dense class="mt-2" prepend-icon="mdi-arrow-up-down" v-model="displayModelAdjust.posY"
                :messages="String(displayModelAdjust.posY)" min="-1" max="1" step="0.01"></v-slider>
            <v-slider dense class="mt-2" prepend-icon="mdi-rotate-right" v-model="displayModelAdjust.rotation"
                :messages="rotationDeg" min="0" max="6.28" step="0.01"></v-slider>
          </div>
        </v-list-item>
      </v-list-group>
    </v-list>
    <template v-else>
      <pre class="pa-3 text--secondary">{{ model.loadingState.text }}</pre>
      <pre v-if="model.error" class="error--text px-3 text-wrap">{{ model.error }}</pre>
    </template>
  </div>
</template>

<script lang="ts">
import { App } from '@/app/App';
import { Filter } from '@/app/Filter';
//import { Live2DModel } from '@/app/Live2DModel';
import { ModelEntity } from '@/app/ModelEntity';
import { clamp } from 'lodash-es';
import { MotionPriority, MotionState } from 'pixi-live2d-display';
import { SpeechController } from '@/app/SpeechController';
import { DisplayModel } from '@/app/DisplayModel';
import { defineComponent } from 'vue';
import { MessageSegment } from '@/app/MessageSegment';

interface MotionGroupEntry {
    name: string
    motions: {
        file: string;
        error?: any;
    }[]
}

interface ExpressionEntry {
    file: string;
    error?: any;
}

export default defineComponent({
    name: 'ModelEditor',
    props: {
        id: {
            type: Number,
            default: 0
        },
        displayModel: {
            type: DisplayModel,
            default: new DisplayModel()
        },
        messageSegment: { 
            type: MessageSegment,
            default: new MessageSegment()
        },
        visible: Boolean
    },
    data: () => ({
        model: null as ModelEntity | null | undefined,

        motionExpand: false,
        motionGroups: [] as MotionGroupEntry[],
        motionState: null as MotionState | null | undefined,

        motionProgressTimerID: -1,

        expressions: [] as ExpressionEntry[],
        currentExpressionIndex: -1,
        pendingExpressionIndex: -1,

        filters: Object.keys(Filter.filters),

        mouseInterval: -1,
        mouseCheckCount: 0,
        previousX: -1,
        previousY: -1,

        speechController: new SpeechController(),

        displayModelAdjust: new DisplayModel()
    }),
    computed: {
        hasPixiModel(): boolean {
            return !!this.motionState;
        },
        rotationDeg(): string {
            return Math.round((this.model?.rotation || 0) / Math.PI * 180) + '°';
        }
    },
    watch: {
        id: {
          immediate: true,
          handler(val) {
            val == 0 ? this.resetModel() : this.updateModel();
          }
        },
        displayModel: {
          deep: true,
          handler(val) {
            this.displayModelAdjust = val;
          }
        },
        displayModelAdjust: {
          deep: true,
          handler() {
            this.SetModelPosition();
          }
        },
        messageSegment: {
          handler(segment) {
            this.speechController?.EnqueueAndPlay(segment);
          }
        },
        'model.filters'() {
          this.model?.updateFilters();
        },
        // immediately update progress when current motion has changed
        'motionState.currentGroup': 'updateMotionProgress'
    },
    mounted() {
        this.motionProgressTimerID = window.setInterval(this.updateMotionProgress, 50);
        this.speechController.addEventListener('speaking', (e: any) => {
            this.$emit('speaking', e.detail);
        });
    },
    methods: {
        updateModel() {
            this.resetModel();

            this.model = App.getModel(this.id);

            if (this.model) {
                if (this.model.pixiModel) {
                    this.pixiModelLoaded(this.model.pixiModel);
                } else {
                    this.model.once('modelLoaded', this.pixiModelLoaded);
                }
            }
        },
        resetModel() {
            if (this.model) {
                this.speechController.UnloadCharacterSpeak();
                this.model.off('modelLoaded', this.pixiModelLoaded);
                this.model.pixiModel?.off('expressionSet', this.expressionSet);
                this.model.pixiModel?.off('expressionReserved', this.expressionReserved);
                // @ts-ignore
                this.model.pixiModel?.internalModel.motionManager?.off('motionLoadError', this.motionLoadError);
                // @ts-ignore
                this.model.pixiModel?.internalModel.motionManager?.expressionManager?.off('expressionLoadError', this.expressionLoadError);
                this.motionGroups = [];
                this.motionState = undefined;
                this.model.destroy();
                this.model = undefined;
                if (this.mouseInterval != -1) {
                  clearInterval(this.mouseInterval);
                  this.mouseInterval = -1;
                }
            }
        },
        pixiModelLoaded(pixiModel: any) { // Live2DModel
            console.log(pixiModel);
            //console.log(document.getElementById('canvas'));

            window.onresize = () => { this.SetModelPosition(); };

            const motionManager = pixiModel.internalModel.motionManager;
            const motionGroups: MotionGroupEntry[] = [];

            const definitions = motionManager.definitions;

            for (const [group, motions] of Object.entries(definitions)) {
                const thisMotions: any = motions;
                motionGroups.push({
                    name: group,
                    motions: thisMotions?.map((motion: any, index: number) => ({
                        file: motion.file || motion.File || '',
                        error: motionManager.motionGroups[group]![index]! === null ? 'Failed to load' : undefined,
                    })) || [],
                });
            }

            this.motionGroups = motionGroups;
            this.motionState = motionManager.state;

            const expressionManager = motionManager.expressionManager;
            this.expressions = expressionManager?.definitions.map((expression: any, index: number) => ({
                file: expression.file || expression.File || '',
                error: expressionManager!.expressions[index]! === null ? 'Failed to load' : undefined,
            })) || [];

            this.currentExpressionIndex = expressionManager?.expressions.indexOf(expressionManager!.currentExpression) ?? -1;
            this.pendingExpressionIndex = expressionManager?.reserveExpressionIndex ?? -1;

            pixiModel.on('expressionSet', this.expressionSet);
            pixiModel.on('expressionReserved', this.expressionReserved);
            motionManager.on('motionLoadError', this.motionLoadError);
            expressionManager?.on('expressionLoadError', this.expressionLoadError);

            this.mouseInterval = window.setInterval(() => {
              // Re-center look after focus hasn't moved for a period of time
              if(this.previousX == pixiModel.internalModel.focusController.x && this.previousY == pixiModel.internalModel.focusController.y)
              {
                if (this.mouseCheckCount == 3) {
                  pixiModel.internalModel.focusController.focus(0, 0); // Center look
                } else {
                  this.mouseCheckCount++;
                }
              } else {
                this.mouseCheckCount = 0;
                this.previousX = pixiModel.internalModel.focusController.x;
                this.previousY = pixiModel.internalModel.focusController.y;
              }
            }, 1000);
            
            this.speechController.InitCharacterSpeak(pixiModel);
            this.$emit('characterLoaded', pixiModel);
        },
        expressionSet(index: number) {
            this.currentExpressionIndex = index;
        },
        expressionReserved(index: number) {
            this.pendingExpressionIndex = index;
        },
        motionLoadError(group: string, index: number, error: any) {
            const motionGroup = this.motionGroups.find(motionGroup => motionGroup.name === group);

            if (motionGroup) {
                motionGroup.motions[index]!.error = error;
            }
        },
        expressionLoadError(index: number, error: any) {
            this.expressions[index]!.error = error;
        },
        startMotion(motionGroup: MotionGroupEntry, index: number) {
            this.model?.pixiModel?.motion(motionGroup.name, index, MotionPriority.FORCE);
        },
        setExpression(index: number) {
            this.model?.pixiModel?.expression(index);
        },
        updateMotionProgress() {
            if (!(this.model?.pixiModel && this.motionState?.currentGroup !== undefined && this.motionExpand && this.visible && this.$el)) {
                return;
            }

            const startTime = this.model.pixiModel.currentMotionStartTime;
            const duration = this.model.pixiModel.currentMotionDuration;
            const progress = clamp((this.model.pixiModel.elapsedTime - startTime) / duration, 0, 1);

            // using a CSS variable can be a lot faster than letting Vue update a style object bound to the element
            // since that will cause the component to re-render
            (this.$el as HTMLElement).style.setProperty('--progress', progress * 100 + '%');
        },
        SetModelPosition() {
          if(this.model) {
            const canvas = document.getElementById('canvas');
            const canvasHeight = canvas?.clientHeight || 0;
            const canvasWidth = canvas?.clientWidth || 0;
            const scale = canvasHeight / 1000 * this.displayModel.scale;
            this.model.scale(scale, scale);
            this.model.pixiModel?.position.set((canvasWidth / 2) + (canvasWidth * this.displayModel.posX), (canvasHeight / 2) + (canvasHeight * this.displayModel.posY));
            this.model.rotation = this.displayModel.rotation;
            //}
          }
        }
    },
    beforeUnmount() {
        this.resetModel();
        clearInterval(this.motionProgressTimerID);
    }
});
</script>

<style scoped lang="stylus">
.ptb-12
    padding 12px 0 !important

.v-list-item
    padding: 12px 24px !important

.motion-progress
    position absolute
    z-index -1
    top 0
    bottom 0
    left 0
    right 0
    opacity .24
    background linear-gradient(var(--v-primary-base), var(--v-primary-base)) no-repeat
    background-size var(--progress, 0) auto
</style>
