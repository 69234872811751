import { ModelEntity } from './ModelEntity';
import { ExtendedFileList } from 'pixi-live2d-display';
import Stats from 'stats.js';
import { PixiApp } from '@/app/PixiApp';
import { Live2DModel } from '@/app/Live2DModel';

const stats = new Stats();
stats.showPanel(0);
stats.dom.style.left = '';
stats.dom.style.right = '0';

export class App {
    static models: ModelEntity[] = [];

    static pixiApp = new PixiApp(stats);

    static addModel(source: string | ExtendedFileList): number {
        this.pixiApp.renderer.resize(window.innerWidth, window.innerHeight);
        window.onresize = () =>
        {
            this.pixiApp.renderer.resize(window.innerWidth, window.innerHeight);
        }

        const model = new ModelEntity(source, this.pixiApp.renderer);

        this.initModel(model);
        this.models.push(model);

        return model.id;
    }

    static getModel(id: number) {
        return this.models.find(m => m.id === id);
    }

    private static initModel(model: ModelEntity) {
        model.on('modelLoaded', async (pixiModel: Live2DModel) => {
            if (!this.pixiApp.stage.children.includes(pixiModel)) {
                this.pixiApp.stage.addChild(pixiModel);
                pixiModel.position.set(this.pixiApp.renderer.width / 2, this.pixiApp.renderer.height / 2);
                model.fit(this.pixiApp.renderer.width, this.pixiApp.renderer.height);
            }
        });
    }

    static removeModel(id: number) {
        const model = this.models.find(model => model.id === id);

        if (model) {
            this.models.splice(this.models.indexOf(model), 1);

            if (model.pixiModel) {
                this.pixiApp.stage.removeChild(model.pixiModel);
            }

            model.destroy();
        }
    }
}
