import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3373393c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settings pa-3 flex-column flex-grow-1" }
const _hoisted_2 = { class: "mt-2 d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_ctx.currentBackground)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_v_divider),
            _createVNode(_component_v_list_subheader, { class: "px-0" }, {
              default: _withCtx(() => [
                _createTextVNode("Background")
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(_ctx.currentBackground), 1),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "secondary",
                onClick: _ctx.resetBackground
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Reset")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}