<template>
        <div class="header pa-3">
          <div class="d-flex align-center logout-row">
            <div class="text-h6">Logout</div>
            <v-spacer></v-spacer>
            <v-btn icon width="48" height="48" class="mr-n3" @click="logout()" elevation="0">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </div>
        </div>
  </template>
  
  <script lang="ts">
  import { Configuration } from '@/utils/Configuration';
  import { RedirectLoginOptions, createAuth0Client } from '@auth0/auth0-spa-js';
  import { defineComponent } from 'vue';

  export default defineComponent({
      name: "LogOut",
      methods: {
        logout() {
            console.log('Logging out...');
            fetch(Configuration.ApiUrl + '/logout', {
                credentials: 'include',
                method: "POST"
            }).then(async () => {
                if (Configuration.LocalAuth) {
                    window.location.href = Configuration.SignInUrl;
                } else {
                    const auth0Config = {
                        "domain": Configuration.Auth0Domain,
                        "clientId": Configuration.Auth0ClientId
                    }
                    const auth0RedirectConfig: RedirectLoginOptions = {
                        authorizationParams: {
                            redirect_uri: Configuration.Auth0RedirectUri
                        }
                    }
                    const auth0Client = await createAuth0Client(auth0Config);
                    await auth0Client.loginWithRedirect(auth0RedirectConfig);
                }
            });
        }
    }
  });
  </script>
  
  <style scoped lang="stylus">
  .v-list-group :deep(.v-list-item)
    padding: 0 12px !important

  .v-list-item__content
    padding: 0
  
  .logout-row
    padding 0 12px
    
 </style>
  