import { readAsBase64 } from '@/utils/file';
import { EventEmitter } from '@pixi/utils';

const BG_STORAGE_KEY = '_bg';

export namespace Background {
    let currentObjectURL = '';
    const defaultBg = 'default-bg';

    let styleElement: HTMLStyleElement | undefined;

    export const emitter = new EventEmitter();

    export let current: string = '';

    export async function set(file: File) {
        document.body.classList.remove(defaultBg);

        const objectURL = URL.createObjectURL(file);

        document.body.style.backgroundImage = `url(${objectURL})`;

        current = file.name;

        emitter.emit('change', current);

        currentObjectURL = objectURL;

        const base64 = await readAsBase64(file);

        localStorage.setItem(BG_STORAGE_KEY, base64);

        URL.revokeObjectURL(objectURL);
    }

    export async function setFromUrl(url: string) {
        document.body.classList.remove(defaultBg);
        
        document.body.style.backgroundImage = `url(${url})`;

        emitter.emit('change', current);
    }

    export function reset() {
        document.body.classList.remove(defaultBg);
        
        current = '';
        document.body.style.backgroundImage = '';
        document.body.style.backgroundColor = '#222222';
        localStorage.removeItem(BG_STORAGE_KEY);

        if (styleElement) {
            document.head.removeChild(styleElement);
            styleElement = undefined;
        }

        emitter.emit('change', current);
    }

    export function loadBackground() {
        const dataURL = localStorage.getItem('_bg');
        if (dataURL) {
            document.body.classList.remove(defaultBg);
            document.body.style.backgroundImage = `url('${dataURL}')`;
            current = '(local file)';
        } else {
            document.body.classList.add(defaultBg);
            document.body.style.backgroundImage = "url('/Backgrounds/default.gif')"; // Credit: https://dribbble.com/shots/4842161-voice-wave
        }
    }
}
