<template>
  <div class="settings pa-3 flex-column flex-grow-1">
    <div>
      <template v-if="currentBackground">
        <v-divider></v-divider>
        <v-list-subheader class="px-0">Background</v-list-subheader>
        <div class="mt-2 d-flex align-center">
          <span>{{ currentBackground }}</span>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="resetBackground">Reset</v-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Background } from '@/tools/Background';
import { defineComponent } from 'vue';

export default defineComponent({
    name: "ModelSettings",
    data: () => ({
        currentBackground: Background.current
    }),
    created() {
        Background.emitter.on('change', this.backgroundChanged, this);
    },
    methods: {
        resetBackground() {
            Background.reset();
        },
        backgroundChanged(background: string) {
            this.currentBackground = background;
        }
    },
    beforeUnmount() {
        Background.emitter.off('change', this.backgroundChanged);
    }
});
</script>

<style scoped lang="stylus">
.settings
  display flex
</style>
