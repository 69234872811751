<template>
    <v-dialog persistent width="1000" max-width="90vw" v-model="isActiveLocal">
      <v-card height="90vh" class="d-flex flex-column">
        <v-toolbar color="primary" flat dense class="flex-grow-0">
          <v-toolbar-title>Chat Management</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="d-flex align-center add-char-row pa-3 ptb-6">
            <div class="text-h6 chat-new">Start New Chat</div>
            <v-btn icon width="48" height="48" class="mr-n3" @click="$emit('newchat');">
              <v-icon>mdi-new-box</v-icon>
            </v-btn>
        </div>
        <div class="text-h6 chat-log">Chat Log:</div>
        <v-card-text class="pa-3 overflow-auto">
          <pre class="code">{{ chatLog }}</pre>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  
  <script lang="ts">
  import { PropType, defineComponent } from 'vue';
  import { ChatType } from '../data/ChatType';
  
  export default defineComponent({
      name: "ChatInfo",
      props: {
        isActive: Boolean,
        chatHistory: Array as PropType<Array<ChatType>>
      },
      data: () => ({
          chatLog: 'Loading...',
          isActiveLocal: false
      }),
      watch: {
        chatHistory: {
          handler(val) {
            this.updateChatHistory(val);
          }
        },
        isActive() {
          this.isActiveLocal = this.isActive;
        }
      },
      methods: {
        updateChatHistory(chatHistory: Array<ChatType>) {
              if (chatHistory) {
                this.chatLog = '';
                chatHistory.forEach((chatMessage) => {
                  const chatTimestamp = new Date(chatMessage.timestamp);
                  const timestampFormat = chatTimestamp.getDate() + " " + chatTimestamp.toLocaleString('default', { month: 'short' }) + " " +
                  chatTimestamp.getFullYear() + " " + chatTimestamp.toLocaleTimeString();
                  this.chatLog += "[" + timestampFormat + "] " + chatMessage.speakerName + ": " + chatMessage.message + "\n\n";
                });
              }
          }
      }
  });
  </script>
  
  <style scoped lang="stylus">
  .code
    font-family Consolas, monospace
    white-space: pre-wrap

  .chat-log
    padding-left: 12px
    padding-bottom: 24px

  .chat-new
    padding-right: 12px


  </style>
