import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a20f0456"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "empty"
}
const _hoisted_2 = {
  key: 0,
  class: "empty"
}
const _hoisted_3 = { class: "footer d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_treeview = _resolveComponent("v-treeview")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    persistent: "",
    scrim: "#000",
    modelValue: _ctx.isActiveLocal,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isActiveLocal) = $event)),
    width: "1500",
    "max-width": "90vw"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        height: "1000",
        "max-height": "95vh",
        class: "d-flex flex-column"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, {
            color: "primary",
            flat: "",
            dense: "",
            class: "flex-grow-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Select a Character")
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                icon: "",
                class: "ml-1 menu-close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-close")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _withDirectives(_createVNode(_component_v_row, {
            "no-gutters": "",
            class: "content-row flex-grow-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { class: "tree-col" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_treeview, {
                    activatable: "",
                    items: _ctx.characters,
                    "open-on-click": "",
                    activated: _ctx.active,
                    "onUpdate:activated": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.active) = $event)),
                    "open-strategy": "single"
                  }, {
                    prepend: _withCtx(({ item }) => [
                      _createVNode(_component_v_icon, { class: "folder" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.children ? 'mdi-folder' : 'mdi-account'), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createTextVNode(" " + _toDisplayString(item.name) + " ", 1),
                      (item.children)
                        ? (_openBlock(), _createBlock(_component_v_chip, { key: 0 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.children.length), 1)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["items", "activated"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider, { vertical: "" }),
              _createVNode(_component_v_col, { class: "content-col" }, {
                default: _withCtx(() => [
                  (!_ctx.selected)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, " No Character Selected "))
                    : (_openBlock(), _createBlock(_component_v_card, {
                        key: _ctx.selected.value,
                        flat: "",
                        class: "char-card"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_avatar, { size: "225" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_img, {
                                    src: _ctx.getAvatar(_ctx.selected.value)
                                  }, null, 8, ["src"])
                                ]),
                                _: 1
                              }),
                              _createElementVNode("h3", null, _toDisplayString(_ctx.selected.name), 1),
                              _createElementVNode("div", null, _toDisplayString(_ctx.selected.description), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.$vuetify.display.mdAndUp]
          ]),
          _withDirectives(_createVNode(_component_v_row, {
            "no-gutters": "",
            class: "content-row-sm-container flex-grow-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "content-row-sm" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_treeview, {
                    activatable: "",
                    items: _ctx.characters,
                    "open-on-click": "",
                    activated: _ctx.active,
                    "onUpdate:activated": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.active) = $event)),
                    "open-strategy": "single"
                  }, {
                    prepend: _withCtx(({ item }) => [
                      _createVNode(_component_v_icon, { class: "folder" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.children ? 'mdi-folder' : 'mdi-account'), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createTextVNode(" " + _toDisplayString(item.name) + " ", 1),
                      (item.children)
                        ? (_openBlock(), _createBlock(_component_v_chip, { key: 0 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.children.length), 1)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["items", "activated"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_row, { class: "content-row-sm content-col" }, {
                default: _withCtx(() => [
                  (!_ctx.selected)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, " No Character Selected "))
                    : (_openBlock(), _createBlock(_component_v_card, {
                        key: _ctx.selected.value,
                        flat: "",
                        class: "char-card"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_avatar, { size: "160" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_img, {
                                    src: _ctx.getAvatar(_ctx.selected.value)
                                  }, null, 8, ["src"])
                                ]),
                                _: 1
                              }),
                              _createElementVNode("h3", null, _toDisplayString(_ctx.selected.name), 1),
                              _createElementVNode("div", null, _toDisplayString(_ctx.selected.description), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.$vuetify.display.smAndDown]
          ]),
          _createVNode(_component_v_divider),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_btn, {
              color: "blue-grey",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Cancel "),
                _createVNode(_component_v_icon, { class: "v-btn-right-icon" }, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-close-circle-outline")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_btn, {
              color: "blue-grey",
              onClick: _ctx.submit
            }, {
              default: _withCtx(() => [
                _createTextVNode("Select "),
                _createVNode(_component_v_icon, { class: "v-btn-right-icon" }, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-checkbox-marked-circle-outline")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}