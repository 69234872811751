<template>
  <v-dialog persistent max-width="800" scrim="#000" :fullscreen="$vuetify.display.smAndDown" v-model="isActiveLocal">
    <v-card>
      <v-toolbar color="primary" flat dense class="flex-grow-0">
        <v-toolbar-title>Load Character</v-toolbar-title>
        <v-btn icon class="ml-1 menu-close" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-8 container">
        <div>
          <div class="empty" v-if="!selected">
            ?
          </div>
          <v-card v-else :key="selected.value" flat class="char-card">
            <v-card-text>
              <v-avatar size="160">
                <v-img :src="selected.avatar"></v-img>
              </v-avatar>
              <h3>
                {{ selected.name }}
              </h3>
              <div class="description">
                {{ selected.description }}
              </div>
            </v-card-text>
          </v-card>
          <v-text-field single-line filled label="URL" v-model="url" :messages="urlMessages"
            :error="urlError" @keyup.enter="create" v-on:keyup="setCharacter"></v-text-field>
          </div>
          <div class="footer">
            <div class="d-flex align-center">
            <v-btn color="blue-grey" @click="pickerDialog = true">Browse...
              <v-icon class="v-btn-right-icon">mdi-cloud-search</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue-grey" @click="create">Load
              <v-icon class="v-btn-right-icon">mdi-checkbox-marked-circle-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <ModelPicker v-model="pickerDialog" @close="pickerDialog = false" @select="select($event)"/>
  </v-dialog>
</template>

<script lang="ts">
import ModelPicker from './ModelPicker.vue';
import { App } from '@/app/App';
import { defineComponent } from 'vue';
import characters from '../data/characters';

export default defineComponent({
    components: { ModelPicker },
    name: "ModelCreation",
    props: {
        isActive: Boolean
    },
    data: () => ({
        url: localStorage.getItem('SelectedCharacter') ? localStorage.getItem('SelectedCharacter') : '/Live2D/Sample/Haru_Greeter/haru_greeter_t05.model3.json',
        dropHelpDialog: false,
        urlError: false,
        urlMessages: [] as string[],
        pickerDialog: false,
        isActiveLocal: false,
        characters: characters,
        selected: null
    }),
    watch: {
        isActive() {
          this.isActiveLocal = this.isActive;
        }
    },
    methods: {
        async create() {
            this.url = this.url.replace(/\s/g, '');
            if (this.url) {
              await fetch(this.url, {method: 'HEAD'}).then(res => {
                if (res.status != 200 || res.headers.get('X-Cache') == 'Error from cloudfront') {
                  this.urlError = true;
                  this.urlMessages = ['Invalid URL'];
                }  
              });
            } else {
                this.urlError = true;
                this.urlMessages = ['Please enter a URL'];
            }
            if (this.urlError) {
                return;
            }
            localStorage.setItem('SelectedCharacter', this.url);
            const id = App.addModel(this.url);
            this.$emit('create', id);
        },
        select(url) {
          this.pickerDialog = false;
          this.url = url;
          this.setCharacter();
        },
        setCharacter() {
          this.urlError = false;
          this.urlMessages = [];
          let found = false;
          for (let i = 0; i < this.characters.length; i++) {
            const character = characters[i].children.find(character => character.value.replace(/ /g, '%20') === this.url);
            if (character) {
              this.selected = {
                name: character.name,
                description: character.description,
                avatar: character.value.replace('.json', '.jpg')
              }
              found = true;
              return;
            }
            if (!found) {
              this.selected = null;
            }
          }
        }
    },
    created() {
      this.setCharacter();
    }
});
</script>

<style scoped lang="stylus">
.selected
  height: 35vh
  display: flex
  justify-content: center
  align-items: center

.empty
  font-size: 10em
  text-align: center

.char-card
  text-align: center

.v-card-text h3
  text-align: center
  padding: 16px

.description
  max-width: 350px
  margin: 0 auto

.container
  display: flex
  flex-direction: column
  justify-content: space-between

.footer
  margin-top: 8px
</style>
