export const Configuration = {
    RunMode: process.env.VUE_APP_RUN_MODE,
    VisionUrl: process.env.VUE_APP_VISION_URL,
    WebSocketUrl: process.env.VUE_APP_WEB_SOCKET_URL,
    ApiUrl: process.env.VUE_APP_API_URL,
    SignInUrl: process.env.VUE_APP_SIGN_IN_URL,
    LocalAuth: (process.env.VUE_APP_LOCAL_AUTH === "true"),
    Auth0Domain: process.env.VUE_APP_AUTH0_DOMAIN,
    Auth0ClientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    Auth0RedirectUri: process.env.VUE_APP_AUTH0_REDIRECT_URI,
    StripePubKey: process.env.VUE_APP_STRIPE_PUB_KEY,
    StripeCustomerPortalUrl: process.env.VUE_APP_STRIPE_CUSTOMER_PORTAL_URL
}
