export class DisplayModel {
    private _posX: number;
    private _posY: number;
    private _scale: number;
    private _rotation: number;
    private _name: string;

    constructor(posX: number = 0, posY: number = 0, scale: number = 1, rotation: number = 0, name: string = '') {
        this._posX = posX,
        this._posY = posY,
        this._scale = scale;
        this._rotation = rotation;
        this._name = name;
    }

    public get posX() {
        return this._posX;
    }

    public set posX(val: number) {
        this._posX = val;
    }

    public get posY() {
        return this._posY;
    }

    public set posY(val: number) {
        this._posY = val;
    }

    public get scale() {
        return this._scale;
    }

    public set scale(val: number) {
        this._scale = val;
    }

    public get rotation() {
        return this._rotation;
    }

    public set rotation(val: number) {
        this._rotation = val;
    }

    public get name() {
        return this._name;
    }

    public set name(val: string) {
        this._name = val;
    }
}
