<template>
  <v-dialog width="auto" max-width="800" :value="value" @input="$emit('input', $event)" scrollable>
    <v-card class="d-flex flex-column">
      <v-toolbar color="primary" flat dense class="flex-grow-0">
        <v-toolbar-title>Subscription</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-8">
        <div id="checkout"></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
<script lang="ts">
  import getStripe from '@/utils/getStripe';
  import { SubscriptionType } from '@/data/SubscriptionType';
  import { Configuration } from '@/utils/Configuration';
  import { defineComponent } from 'vue';
  
  export default defineComponent({
      name: "SubscriptionInfo",
      props: {
        value: Boolean,
        subscriptionType: {
          type: SubscriptionType,
          default: null
        }
      },
      watch: {
        async subscriptionType(subscriptionType: SubscriptionType) {
          const intvId = setInterval(async () => {
            if (!this.value) {
              clearInterval(intvId);
              this.$emit('refreshsubscription');
            }
          }, 1000);
          if (subscriptionType) {
            const stripe = await getStripe();
            const checkoutEl = document.getElementById("checkout");
            if (checkoutEl?.childNodes.length == 0) {
              await fetch(Configuration.ApiUrl + '/payment/create-checkout-session/' + '2', {
                credentials: 'include',
                method: "POST"
              }).then(async resp => {
                const { clientSecret } = await resp.json();
                await stripe?.initEmbeddedCheckout({
                  clientSecret
                }).then(async (checkout) => {
                  checkout?.mount('#checkout');
                  /*const intvId = setInterval(async () => {
                    await fetch(Configuration.ApiUrl + '/payment/session-status', {
                      credentials: 'include',
                      method: "POST",
                      body: checkout.embeddedCheckout.checkoutSessionId
                    }).then(async respStatus => {
                      const status = await respStatus.json();
                      console.log(status);
                    });
                  }, 1000);*/
                });
              });
            }
          }
        }
      }
  });
</script>
