import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Configuration } from './Configuration';

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(Configuration.StripePubKey!);
  }
  return stripePromise;
};

export default getStripe;
